/** @jsx jsx */
import { jsx } from 'theme-ui'
import ReserveService from '@/components/Home/reserveService'
import Search from '@/components/Home/Search'
import PopularProjects from '@/components/Home/PopularProjects'
import Foot from '@/components/Home/Foot'
import Register from '@/components/Home/Register'
import RegisterVoice from '@/components/Home/RegisterVoice'
import ContactPopup from '@/components/Home/ContactPopup'

const Main = () => {
  return (
    <div>
      <ContactPopup />
      <ReserveService />
      <Search />
      <Foot />
      <PopularProjects />
      <RegisterVoice />
      <Register />
    </div>
  )
}

export default Main
