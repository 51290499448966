// extracted by mini-css-extract-plugin
export var btn = "ContactPopup-module--btn--848e5";
export var close_btn = "ContactPopup-module--close_btn--05c8b";
export var inner_wrap = "ContactPopup-module--inner_wrap--6b5df";
export var input_email = "ContactPopup-module--input_email--799ff";
export var input_name = "ContactPopup-module--input_name--9e5af";
export var input_name_set = "ContactPopup-module--input_name_set--e61da";
export var input_set = "ContactPopup-module--input_set--255c4";
export var popup_input = "ContactPopup-module--popup_input--49b2f";
export var popup_p = "ContactPopup-module--popup_p--d836a";
export var popup_wrap = "ContactPopup-module--popup_wrap--0dc3f";
export var send_mail = "ContactPopup-module--send_mail--bb90e";