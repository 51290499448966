/** @jsx jsx */
import { Divider, jsx } from 'theme-ui'
import { Fragment, useState } from 'react'
import * as styles from '@/styles/common/ContactPopup.module.scss'
import { isMobile } from 'react-device-detect'
import { TbMailShare } from 'react-icons/tb'
import { GoX } from 'react-icons/go'

const XDefault = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
}

const ContactPopup = () => {
  const [textField, setTextField] = useState(XDefault)
  const [openState, setOpenState] = useState<boolean>(true)
  const inputOnChangeHandler = (e) => {
    const { name, value } = e.target
    setTextField((e) => ({
      ...textField,
      [name]: value,
    }))
  }

  const openHandler = () => {
    setOpenState((e) => !e)
  }

  const checkInput = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/
    if (textField.firstName.length < 1 || textField.lastName.length < 1) {
      alert('名前を1文字以上入力してください。')
      return false
    } else if (textField.email.length < 1) {
      alert('メールアドレスを1文字以上入力してください。')
      return false
    } else if (!emailRegex.test(textField.email)) {
      alert('正しいメールアドレス形式で入力してください。')
      return false
    } else if (textField.phoneNumber.length < 1) {
      alert('電話番号を1文字以上入力してください。')
      return false
    } else if (
      textField.phoneNumber.length < 11 ||
      textField.phoneNumber.length > 11
    ) {
      alert('正しい11桁の携帯電話番号を入力してください。')
      return false
    } else {
      onSubmit()
    }
  }

  const onSubmit = async () => {
    const { firstName, lastName, email, phoneNumber } = textField
    const response = await fetch(
      'https://1u4nvii4ke.microcms.io/api/v1/contact',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Microcms-Api-Key': 'SZ4ZDySSGmuOZkbtrjYn4Lv26yorLaJHgpLk',
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          phoneNumber,
        }),
      },
    )
      .then((data) => {
        alert('相談申し込みが完了しました。')
        setTextField((e) => ({ ...XDefault }))
      })
      .catch((error) => {
        alert('少々後でもう一度お試しください。')
      })
  }

  return (
    <Fragment>
      {!!openState ? (
        <div className={styles.popup_wrap}>
          <div className={styles.close_btn} onClick={openHandler}>
            {isMobile && <GoX />}
          </div>
          <div className={styles.inner_wrap}>
            <div className={styles.input_set}>
              <p className={styles.popup_p}>名前*</p>
              <div className={styles.input_name_set}>
                <input
                  name="firstName"
                  value={textField.firstName}
                  placeholder="姓"
                  className={`${styles.input_name} ${styles.popup_input}`}
                  onChange={(e) => inputOnChangeHandler(e)}
                />
                <input
                  name="lastName"
                  value={textField.lastName}
                  placeholder="名"
                  className={`${styles.input_name} ${styles.popup_input}`}
                  onChange={(e) => inputOnChangeHandler(e)}
                />
              </div>
            </div>
            <div className={styles.input_set}>
              <p className={styles.popup_p}>メールアドレス*</p>
              <input
                name="email"
                value={textField.email}
                placeholder="mail@example.com"
                className={`${styles.popup_input} ${styles.input_email}`}
                onChange={(e) => inputOnChangeHandler(e)}
              />
            </div>
            <div className={styles.input_set}>
              <p className={styles.popup_p}>電話番号*</p>
              <input
                name="phoneNumber"
                value={textField.phoneNumber}
                placeholder="08012345678"
                className={`${styles.popup_input}`}
                onChange={(e) => inputOnChangeHandler(e)}
              />
            </div>
            <button className={styles.btn} onClick={checkInput}>
              相談申請
            </button>
          </div>
        </div>
      ) : (
        <div onClick={openHandler} className={styles.send_mail}>
          <TbMailShare />
        </div>
      )}
    </Fragment>
  )
}

export default ContactPopup
