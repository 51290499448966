/** @jsx jsx */
import { jsx } from 'theme-ui'
import { BackgroundFill } from '../Common/BackgroundFill'

const Foot = () => {
  const DarkNavy = '#082567'
  const comments = [
    {
      title: '仕事の受注が不安定で、収入が安定しない',
      content: '長期的な計画が立てづらく、経済的に不安を感じる。',
    },
    {
      title: '新しいプロジェクトを見つけるのが大変',
      content: 'ネットワークが限られていて、次の仕事が見つかるか不安だ。',
    },
    {
      title: 'クライアントとのコミュニケーションがうまくいかない',
      content: '要求が不明確で、作業が進めづらいと感じることが多い。',
    },
    {
      title: 'スキルアップが必要だけど、時間が取れない',
      content: '新しい技術を学びたいが、忙しすぎて手が回らない。',
    },
  ]

  const handleButtonClick = () => {
    open(
      'https://timerex.net/s/info_f08c_3b71/2a13158e',
      '_blank',
      'noopener,noreferrer',
    )
  }
  return (
    <div
      sx={{
        backgroundColor: 'rgb(236, 241, 247)',
        objectFit: 'cover',
        maxWidth: '100%',
        paddingTop: '60px',
        paddingBottom: '40px',
        color: 'black',
        position: 'relative',
      }}
    >
      <BackgroundFill background={'rgb(236, 241, 247)'} />
      <div sx={{ textAlign: 'center' }}>
        <h3>プロジェクト探しで</h3>
        <div sx={{ fontWeight: 600, fontSize: ['1.5em', '2em'] }}>
          こんな<span sx={{ color: `#f28900` }}>悩み</span>ありませんか?
        </div>
        <div
          sx={{
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            marginTop: '70px',
            fontSize: '12px',
            fontWeight: '700',
            padding: '0 20px',
          }}
        >
          {comments.map((value, index) => (
            <div
              sx={{
                width: '100%',
                height: '200px',
                border: `1px solid #999`,
                background: 'white',
                fontSize: '18px',
                padding: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
              }}
              key={index}
            >
              <div>
                <p
                  sx={{
                    color: `#f25900`,
                    fontWeight: '800',
                    paddingBottom: '20px',
                  }}
                >
                  {value.title}
                </p>
                <p>{value.content}</p>
              </div>
            </div>
          ))}
        </div>
        <div
          sx={{
            marginTop: ['40px', '40px', '40px', '50px', '40px'],
            padding: ['20px', '20px', '15px'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: ['1.2rem', '1.2rem', '2rem'],
            fontWeight: 600,
          }}
        >
          <span>その悩み</span>
          <span
            sx={{
              position: 'relative',
              zIndex: '1',
              lineHeight: '1',
              '::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                borderBottom: `12px solid #f28900`,
                zIndex: '-1',
              },
            }}
          >
            エンジビズ
          </span>
          <span>
            が
            <span
              sx={{
                color: `${DarkNavy}`,
              }}
            >
              解決
            </span>
            します
          </span>
        </div>
        <button
          onClick={handleButtonClick}
          sx={{
            background: 'linear-gradient(90deg,#f2b100,#f28900)',
            border: '1px solid #f3af01',
            borderRadius: '5px',
            width: '320px',
            color: 'white',
            fontSize: '20px',
            fontWeight: '600',
            cursor: 'pointer',
            padding: '10px',
            margin: '10px',
            marginBottom: '40px',
          }}
        >
          コンサルタントに相談してみる
        </button>
      </div>
    </div>
  )
}

export default Foot
