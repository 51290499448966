/** @jsx jsx */
import { jsx } from 'theme-ui'

const RECOMMEND_ITEM = [
  {
    skill: 'Python｜経験5年',
    title:
      '異常検知システム開発PJのデータ分析・モデル開発支援｜データサイエンティスト',
    option: ['面接1回', 'リモート可能'],
    salary: 1000000,
  },
  {
    skill: '開発PM｜経験5年',
    title: 'ロボティクスメーカーアプリ開発支援｜PM, PMO',
    option: ['面接1回', 'リモート可能'],
    salary: 1000000,
  },
  {
    skill: 'AIコンサル｜経験5年',
    title: 'AI×マーケティングサービス事業創出支援',
    option: ['面接1回', 'リモート可能'],
    salary: 1200000,
  },
]

const RECOMMEND_FRONT = [
  {
    skill: 'React｜経験5年',
    title: '建材業DXサービス開発支援｜フロントエンドエンジニア',
    option: ['面接1回', 'リモート可能'],
    salary: 1000000,
  },
  {
    skill: 'JavaScript｜経験5年',
    title: 'WEB制作サービス支援｜フロントエンドエンジニア',
    option: ['面接1回', 'リモート可能'],
    salary: 600000,
  },
  {
    skill: 'React｜経験5年',
    title: '製造業DXサービス開発支援｜フロントエンドエンジニア',
    option: ['面接1回', 'リモート可能'],
    salary: 750000,
  },
]

const RECOMMEND_BACK = [
  {
    skill: 'C#｜経験5年',
    title: 'メタバース上で会話可能なAI開発支援｜バックエンドエンジニア',
    option: ['面接1回', 'リモート可能'],
    salary: 700000,
  },
  {
    skill: 'Go｜経験5年',
    title: 'ポイントシステム開発支援｜バックエンドエンジニア',
    option: ['面接1回', 'リモート可能'],
    salary: 800000,
  },
  {
    skill: 'Java/SpringBoot｜経験5年',
    title: 'オンラインゲームPF開発支援｜バックエンドエンジニア',
    option: ['面接1回', 'リモート可能'],
    salary: 800000,
  },
]

const PopularProjects = () => {
  const handleButtonClick = () => {
    window.open(
      'https://timerex.net/s/info_f08c_3b71/2a13158e',
      '_blank',
      'noopener,noreferrer',
    )
  }
  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // height: ['19%', '36%', '38%', '33%', '33%'],
        color: 'white',
        fontFamily: 'Noto Sans JP, sans-serif',
        marginBottom: '120px',
        padding: '0 20px',
      }}
    >
      <div sx={{ color: 'black' }}>
        <h1
          sx={{
            textAlign: 'center',
            margin: ['50px', '50px', 0],
            marginTop: ['50px', '50px', '100px', '100px', '100px'],
            marginBottom: ['50px', '50px', '100px', '100px', '100px'],
          }}
        >
          おすすめの業種別案件
        </h1>
        <div sx={{}}>
          <div>
            <p
              sx={{
                background: '#6c9bd0',
                textAlign: 'center',
                color: 'white',
                padding: '10px',
              }}
            >
              おすすめのSE求人‧案件
            </p>
          </div>
          <div
            sx={{
              background: 'rgb(236, 241, 247)',
              // display: ['none', 'none', 'none', 'none', 'flex'],
              // flexDirection: 'column',
              display: ['flex'],
              flexDirection: ['column', 'row', 'row'],
              height: '100%',
              marginBottom: '20px',
            }}
          >
            {RECOMMEND_ITEM.map((value, index) => (
              <div
                sx={{
                  width: '100%',
                  // height: ['200px', '300px', '300px'],
                  background: '#ffffff',
                  border: '1px solid #ddd',
                  margin: '0 auto',
                  padding: '20px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                }}
                key={index}
              >
                <div
                  sx={{
                    paddingBottom: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <h2
                    sx={{
                      color: '#333',
                      fontSize: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    【{value.skill}】{value.title}
                  </h2>
                  {value.option.map((optionValue, optionIndex) => (
                    <div
                      sx={{
                        background: '#f2f3f5',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        display: 'inline',
                        alignItems: 'flex-start',
                        fontSize: '15px',
                        marginRight: '1px',
                        color: '#666',
                      }}
                      key={optionIndex}
                    >
                      {optionValue}
                    </div>
                  ))}
                  <div
                    sx={{
                      marginTop: '15px',
                      borderTop: '1px solid #ddd',
                      paddingTop: '10px',
                    }}
                  >
                    <img src="/yen-icon.svg" />
                    <span
                      sx={{
                        fontWeight: '700',
                        color: '#f14b4d',
                        fontSize: '1.5rem',
                        marginLeft: '5px',
                      }}
                    >
                      {value.salary.toLocaleString()}
                    </span>
                    円／月
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div style={{ width: '100%', textAlign: 'center' }}>
            <button
              onClick={handleButtonClick}
              sx={{
                border: 'none',
                background: '#22426f',
                borderRadius: '0 0 5px 5px',
                padding: '10px',
                fontWeight: '500',
                color: 'white',
                margin: '0px auto 30px',
                width: ['100%'],
                cursor: 'pointer',
                fontSize: '20px',
              }}
            >
              エントリー相談
            </button>
          </div> */}

          <div>
            <p
              sx={{
                background: '#22426f',
                textAlign: 'center',
                color: 'white',
                padding: '10px 0',
              }}
            >
              おすすめのフロントエンドエンジニア求人‧案件
            </p>
          </div>
          <div
            sx={{
              background: 'rgb(236, 241, 247)',
              display: ['flex'],
              flexDirection: ['column', 'row', 'row'],
              height: '100%',
              marginBottom: '20px',
            }}
          >
            {RECOMMEND_FRONT.map((value, index) => (
              <div
                sx={{
                  width: '100%',
                  // height: ['200px', '300px', '300px'],
                  background: '#ffffff',
                  border: '1px solid #ddd',
                  margin: '0 auto',
                  padding: '20px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                }}
                key={index}
              >
                <div
                  sx={{
                    paddingBottom: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <h2
                    sx={{
                      color: '#333',
                      fontSize: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    【{value.skill}】{value.title}
                  </h2>
                  {value.option.map((optionValue, optionIndex) => (
                    <div
                      sx={{
                        background: '#f2f3f5',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        display: 'inline',
                        alignItems: 'flex-start',
                        fontSize: '15px',
                        marginRight: '1px',
                        color: '#666',
                      }}
                      key={optionIndex}
                    >
                      {optionValue}
                    </div>
                  ))}
                  <div
                    sx={{
                      marginTop: '15px',
                      borderTop: '1px solid #ddd',
                      paddingTop: '10px',
                    }}
                  >
                    <img src="/yen-icon.svg" />
                    <span
                      sx={{
                        fontWeight: '700',
                        color: '#f14b4d',
                        fontSize: '1.5rem',
                        marginLeft: '5px',
                      }}
                    >
                      {value.salary.toLocaleString()}
                    </span>
                    円／月
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div style={{ width: '100%', textAlign: 'center' }}>
            <button
              onClick={handleButtonClick}
              sx={{
                border: 'none',
                background: '#22426f',
                borderRadius: '0 0 5px 5px',
                padding: '10px',
                fontWeight: '500',
                color: 'white',
                margin: '0px auto 30px',
                width: ['100%'],
                cursor: 'pointer',
                fontSize: '20px',
              }}
            >
              エントリー相談
            </button>
          </div> */}

          <div>
            <p
              sx={{
                background: '#10284a',
                textAlign: 'center',
                color: 'white',
                padding: '10px',
              }}
            >
              おすすめのバックエンドエンジニア求人‧案件
            </p>
          </div>
          <div
            sx={{
              background: 'rgb(236, 241, 247)',
              display: 'flex',
              flexDirection: ['column', 'row', 'row'],
              height: '100%',
            }}
          >
            {RECOMMEND_BACK.map((value, index) => (
              <div
                sx={{
                  width: '100%',
                  // height: ['200px', '300px', '300px'],
                  background: '#ffffff',
                  border: '1px solid #ddd',
                  margin: '0 auto',
                  padding: '20px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                }}
                key={index}
              >
                <div
                  sx={{
                    paddingBottom: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <h2
                    sx={{
                      color: '#333',
                      fontSize: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    【{value.skill}】{value.title}
                  </h2>
                  {value.option.map((optionValue, optionIndex) => (
                    <div
                      sx={{
                        background: '#f2f3f5',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        display: 'inline',
                        alignItems: 'flex-start',
                        fontSize: '15px',
                        marginRight: '1px',
                        color: '#666',
                      }}
                      key={optionIndex}
                    >
                      {optionValue}
                    </div>
                  ))}
                  <div
                    sx={{
                      marginTop: '15px',
                      borderTop: '1px solid #ddd',
                      paddingTop: '10px',
                    }}
                  >
                    <img src="/yen-icon.svg" />
                    <span
                      sx={{
                        fontWeight: '700',
                        color: '#f14b4d',
                        fontSize: '1.5rem',
                        marginLeft: '5px',
                      }}
                    >
                      {value.salary.toLocaleString()}
                    </span>
                    円／月
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div style={{ width: '100%', textAlign: 'center' }}>
            <button
              onClick={handleButtonClick}
              sx={{
                border: 'none',
                background: '#22426f',
                borderRadius: '0 0 5px 5px',
                padding: '10px',
                fontWeight: '500',
                color: 'white',
                margin: '0px auto 30px',
                width: ['100%'],
                cursor: 'pointer',
                fontSize: '20px',
              }}
            >
              エントリー相談
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default PopularProjects
